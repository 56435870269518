import Layout from '../../components/site/layout';
import { HomePortrait } from '../../components/site/home';
const SiteIndex = () => {
  return (
    <Layout topbarSticky>
      <HomePortrait />
    </Layout>
  );
};

export default SiteIndex;
