import { Shapes } from '../shapes';
import { ContainerStyled } from '../styles';
import { returnWhatsapp } from '../../../utils/browser';
import React, { useContext } from 'react';
import { CompanyContext } from '../../../context/company.context';
import { useStaticQuery, graphql } from 'gatsby';
import { LangContext } from '../../../context/lang.context';
import Button from '../../button/button';

export const HomePortrait = () => {
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          SITE {
            HOME {
              PORTRAIT {
                TITLE_1
                TITLE_2
                DESCRIPTION
                BUTTON
              }
            }
          }
        }
      }
    }
  `);
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);
  return (
    <div className="relative md:overflow-hidden text-white pt-20">
      <img
        src={getPublicURL(`/theme/assets/images/site/portrait-bg-mobile.svg`)}
        className="md:hidden absolute top-0 left-0 w-full"
        style={{ minHeight: 560, maxHeight: 640 }}
      />
      <Shapes red />

      <ContainerStyled className="flex items-center relative my-auto">
        <div className="hidden md:block w-1/2 py-16">
          <img src={getPublicURL(`/theme/assets/images/site/home-hero-image.svg`)} alt="Lock frame" />
        </div>
        <div className="flex-1 pt-12 md:pt-24">
          <div className="mx-auto md:ml-auto md:mr-24 text-center md:text-left" style={{ maxWidth: 360 }}>
            <h1 className="leading-tight font-light text-4xl md:text-5xl mb-4">
              <b className="font-bold">{t(data).SITE.HOME.PORTRAIT.TITLE_1}</b>
              <br /> {t(data).SITE.HOME.PORTRAIT.TITLE_2}
            </h1>
            <p className="mb-6 mx-6 md:ml-0 md:mr-12 lg:mr-24">{t(data).SITE.HOME.PORTRAIT.DESCRIPTION}</p>
            <Button
              color={'primary'}
              className="mt-32 mb-8 px-12 py-4 leading-none transition-colors duration-200"
              onClick={() => returnWhatsapp()}
            >
              {t(data).SITE.HOME.PORTRAIT.BUTTON}
            </Button>
          </div>
        </div>
      </ContainerStyled>
    </div>
  );
};
